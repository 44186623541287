import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../logo.webp';
import axios from 'axios';
import { apiBaseUrl } from '../../api';
import { toast, Toaster } from 'sonner';

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, seterror] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem('accessToken');

    if (user) {
      navigate('/items');
    }
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const {
        data: { accessToken },
      } = await axios.post(`${apiBaseUrl}/signin`, {
        email,
        password,
      });

      if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        navigate('/items');
      }
      seterror('');
    } catch (error: any) {
      if (error.status === 400) {
        toast.error('Please provide email and password');
      } else if (error.status === 404) {
        toast.error('Provided email or password are incorrect');
      } else {
        toast.error('Error, please try again later');
      }
    }
  };

  return (
    <>
      <div
        className='container d-flex flex-column justify-content-center align-items-center'
        style={{ height: '100vh' }}
      >
        <div>
          <img src={logo} alt='ICTL Holland' style={{ width: '100%' }} />
        </div>

        <form className='row g-3 mt-3 px-4' onSubmit={handleSubmit}>
          <div className='col-md-6'>
            <label htmlFor='inputEmail4' className='form-label'>
              Email
            </label>
            <input
              type='email'
              className='form-control'
              id='inputEmail'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete='email'
            />
          </div>
          <div className='col-md-6'>
            <label htmlFor='inputPassword4' className='form-label'>
              Password
            </label>
            <input
              type='password'
              className='form-control'
              id='inputPassword'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete='password'
            />
          </div>

          <div className='col-12'>
            <button type='submit' className='btn btn-primary'>
              Login
            </button>
          </div>
        </form>
      </div>
      <Toaster richColors />
    </>
  );
}
