import { ReactNode, useEffect, useState } from 'react';
import Header from '../../components/Header';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { apiBaseUrl } from '../../api';

export default function ContainersPage() {
  const [containers, setContainers] = useState<any[]>([]);
  const [displayContainers, setDisplayContainers] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const containersPerPage = 10;

  useEffect(() => {
    const fetchContainers = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/containers/mobile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });

        if (response.status === 200) {
          setContainers(response.data);
          setDisplayContainers(response.data);
          setLoading(false);
        }
      } catch (error) {
        setError('Error...');
        setLoading(false);
      }
    };

    fetchContainers();
  }, []);

  useEffect(() => {
    if (!search.length) {
      setDisplayContainers(containers);
    }
  }, [search]);

  let indexOfLastItem = currentPage * containersPerPage;
  let indexOfFirstItem = indexOfLastItem - containersPerPage;
  const currentContainers = displayContainers?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(displayContainers.length / containersPerPage);

  const paginationRange = () => {
    const range = [];

    if (totalPages <= 3) {
      for (let i = 1; i <= totalPages; i++) {
        range.push(i);
      }
    } else {
      if (currentPage > 2) range.push(1);
      if (currentPage > 3) range.push('...');

      const start = Math.max(2, currentPage);
      const end = Math.min(totalPages - 1, currentPage + 1);

      for (let i = start; i <= end; i++) {
        range.push(i);
      }

      if (currentPage < totalPages - 2) range.push('...');
      if (currentPage < totalPages - 1) range.push(totalPages);
    }

    return range;
  };

  const handleSearchSubmit = (e: any) => {
    e.preventDefault();

    if (search) {
      const filteredContainers = containers.filter((container: any) => {
        return container.container.toUpperCase().includes(search.toUpperCase());
      });

      setDisplayContainers(filteredContainers);
    } else {
      setDisplayContainers(containers);
    }
    setCurrentPage(1);
  };

  let display: ReactNode;

  if (loading) {
    display = (
      <div className='d-flex justify-content-center mt-5'>
        <div
          className='spinner-border text-primary'
          style={{ width: '5rem', height: '5rem' }}
          role='status'
        >
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  } else if (error) {
    display = (
      <div className='container'>
        <div className='alert alert-danger mt-5' role='alert'>
          "Error..."
        </div>
      </div>
    );
  } else {
    display = (
      <div className='container'>
        <div className='row'>
          {currentContainers?.length ? (
            currentContainers.map((container: any) => (
              <div className='col-12' key={container.id}>
                <div className='card mt-2'>
                  <div className='card-body d-flex justify-content-between align-items-center g-5'>
                    <div>
                      <h5 className='card-title'>
                        Container: {container.container}
                      </h5>
                    </div>
                    <div>
                      <Link
                        to={`/containers/${container.id}`}
                        className='btn btn-primary'
                      >
                        Edit
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className='text-center'>No items found</p>
          )}
        </div>

        <nav aria-label='Page navigation' className='my-4'>
          <ul className='pagination justify-content-center'>
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button
                className='page-link'
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <i className='fa-solid fa-chevron-left'></i>
              </button>
            </li>

            {paginationRange().map((page, index) => (
              <li
                key={index}
                className={`page-item ${page === currentPage ? 'active' : ''}`}
              >
                {page === '...' ? (
                  <span className='page-link'>...</span>
                ) : (
                  <button
                    className='page-link'
                    onClick={() => handlePageChange(page as number)}
                  >
                    {page}
                  </button>
                )}
              </li>
            ))}

            <li
              className={`page-item ${
                currentPage === totalPages ? 'disabled' : ''
              }`}
            >
              <button
                className='page-link'
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <i className='fa-solid fa-chevron-right'></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className='container my-4'>
        <form className='d-flex' onSubmit={handleSearchSubmit}>
          <input
            className='form-control me-2'
            type='search'
            placeholder='Search container by #'
            aria-label='Search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button className='btn btn-outline-primary' type='submit'>
            Search
          </button>
        </form>
      </div>
      {display}
    </>
  );
}
