import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';

import ProtectedRoutes from './utils/ProtectedRoutes';

import LoginPage from './pages/LoginPage';
import ItemsPage from './pages/ItemsPage';
import ItemDetailPage from './pages/ItemDetailsPage';
import ContainersPage from './pages/ContainersPage';
import ContainersDetailsPage from './pages/ContainersDetailsPage';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Navigate to='/login' />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      element: <ProtectedRoutes />,
      children: [
        {
          path: '/containers',
          element: <ContainersPage />,
        },
        {
          path: '/containers/:id',
          element: <ContainersDetailsPage />,
        },
        {
          path: '/items',
          element: <ItemsPage />,
        },
        {
          path: '/items/:id',
          element: <ItemDetailPage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
