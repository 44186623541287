import { useParams, useNavigate } from 'react-router-dom';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Header from '../../components/Header';
import axios from 'axios';
import { toast, Toaster } from 'sonner';
import { apiBaseUrl } from '../../api';

function ItemDetailPage() {
  const [item, setItem] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [status, setStatus] = useState('Preparation');
  const [keys, setKeys] = useState('0');
  const [photos, setPhotos] = useState<Blob[]>([]); // Array to store photos as Blob objects
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const fetchItems = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/items/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      setItem(response.data);
      setStatus(response.data.status);
      setKeys(response.data.keys);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchItems();
  }, [id]);

  const handleStatusChangeSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${apiBaseUrl}/items/status/${id}`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success('Item status changed successfuly!');
      }
    } catch (error) {
      toast.error('Error...');
    }
  };

  const handleKeysSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${apiBaseUrl}/items/keys/${id}`,
        { keys },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success('Item keys updated successfuly!');
      }
    } catch (error) {
      toast.error('Error...');
    }
  };

  const handleStartCamera = async () => {
    if (videoRef.current) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: { exact: 'environment' },
            width: { ideal: 1920 },
            height: { ideal: 1080 },
          },
        });
        videoRef.current.srcObject = stream;
      } catch (err) {
        console.error('Error accessing the camera', err);
      }
    }
  };

  const handleCapturePhoto = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              setPhotos((prevPhotos) => [...prevPhotos, blob]);
            }
          },
          'image/jpeg',
          0.92
        );
      }
    }
  };

  const handleUploadPhotos = async () => {
    setUploading(true);
    const formData = new FormData();
    photos.forEach((photo, index) => {
      formData.append(`image[${index}]`, photo, `image${index + 1}.png`);
    });

    try {
      const response = await axios.post(
        `${apiBaseUrl}/items/upload/${item.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 201) {
        toast.success('Photos uploaded successfuly!');
        fetchItems();
        setUploading(false);
      }
      setPhotos([]);
    } catch (error) {
      toast.error('Error...');
    }
  };

  const handleCancelPhotos = () => {
    setPhotos([]);
  };

  useEffect(() => {
    const modalElement = modalRef.current;

    const handleShow = () => {
      handleStartCamera();
    };

    if (modalElement) {
      modalElement.addEventListener('shown.bs.modal', handleShow);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('shown.bs.modal', handleShow);
      }
    };
  }, [modalRef]);

  let display: ReactNode;

  if (loading) {
    display = (
      <div className='d-flex justify-content-center mt-5'>
        <div
          className='spinner-border text-primary'
          style={{ width: '5rem', height: '5rem' }}
          role='status'
        >
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  } else {
    display = (
      <>
        <div>
          <button onClick={() => navigate(-1)} className='btn btn-light btn-sm'>
            <i className='fa-solid fa-arrow-left'></i> Go Back
          </button>
        </div>
        <div>
          <h5 className='mt-4'>
            {item.make} {item.model}, {item.year}
          </h5>
          <h6 className='text-body-secondary'>VIN: {item.vin}</h6>
        </div>
        <div>
          <form
            className='d-flex align-items-end g-2 mt-3'
            onSubmit={handleStatusChangeSubmit}
          >
            <div className='me-2'>
              <label htmlFor='inputStatus' className='form-label'>
                Status
              </label>
              <select
                className='form-select'
                aria-label='Default select example'
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                style={{ width: '250px' }}
              >
                <option value='Preparation'>Preparation</option>
                <option value='Unloaded (not cleared)'>
                  Unloaded (not cleared)
                </option>
                <option value='Customs Control'>Customs Control</option>
                <option value='Cleared'>Cleared</option>
                <option value='Not Paid'>Not Paid</option>
                <option value='Paid - On Hold'>Paid - On Hold</option>
                <option value='Ready to Go'>Ready to Go</option>
                <option value='Not paid - Collected'>
                  Not paid - Collected
                </option>
                <option value='Collected'>Collected</option>
              </select>
            </div>
            <div>
              <button className='btn btn-primary' type='submit'>
                Save
              </button>
            </div>
          </form>
        </div>
        <div>
          <form
            className='d-flex align-items-end g-2 mt-3'
            onSubmit={handleKeysSubmit}
          >
            <div className='me-2'>
              <label htmlFor='inputKeys' className='form-label'>
                Keys
              </label>
              <input
                type='text'
                className='form-control'
                id='inputKeys'
                value={keys}
                onChange={(e) => setKeys(e.target.value)}
                style={{ width: '250px' }}
              />
            </div>
            <div>
              <button className='btn btn-primary' type='submit'>
                Save
              </button>
            </div>
          </form>
        </div>
        <div className='d-flex justify-content-center my-5'>
          <button
            type='button'
            className='btn btn-primary'
            data-bs-toggle='modal'
            data-bs-target='#exampleModal'
          >
            <i className='fa-solid fa-camera'></i> Take photos
          </button>
        </div>
        <div className='mt-4'>
          <h6>Captured photos</h6>
          {photos.length ? (
            <div className='row mb-2'>
              <div className='col'>
                <button
                  className='btn btn-primary'
                  onClick={handleUploadPhotos}
                  disabled={!photos.length}
                >
                  {uploading ? (
                    <div
                      className='spinner-border spinner-border-sm text-light'
                      role='status'
                    >
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  ) : (
                    <i className='fa-solid fa-upload'></i>
                  )}{' '}
                  Upload photos
                </button>
                <button
                  className='btn btn-outline-primary ms-2'
                  onClick={handleCancelPhotos}
                  disabled={!photos.length}
                >
                  Cancel photos
                </button>
              </div>
            </div>
          ) : null}
          <div className='row'>
            {photos.length ? (
              photos.map((photo, index) => (
                <div className='col-6 mb-2' key={index}>
                  <img
                    src={URL.createObjectURL(photo)}
                    className='img-fluid'
                    alt={`Captured ${index + 1}`}
                  />
                </div>
              ))
            ) : (
              <div className='col'>
                <p>No photos captured yet</p>
              </div>
            )}
          </div>
        </div>
        <div className='mt-4'>
          <h6>Current photos</h6>
          <div className='row'>
            {item.uploads.length ? (
              item.uploads
                .filter((upload: any) => upload.uploadType === 'image')
                .map((upload: any) => (
                  <div className='col-6 mb-2' key={upload.id}>
                    <img src={upload.url} className='img-fluid' alt='Image' />
                  </div>
                ))
            ) : (
              <div className='col'>
                <p>No photos yet</p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className='container mt-4'>{display}</div>

      <div
        className='modal fade'
        id='exampleModal'
        tabIndex={-1}
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
        ref={modalRef}
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='exampleModalLabel'>
                Take a Photo
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <video
                ref={videoRef}
                autoPlay
                muted
                playsInline
                className='w-100'
              />
              <canvas ref={canvasRef} style={{ display: 'none' }} />
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-bs-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={handleCapturePhoto}
              >
                Capture
              </button>
            </div>
          </div>
        </div>
      </div>
      <Toaster richColors />
    </>
  );
}

export default ItemDetailPage;
